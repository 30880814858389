<template>
  <v-container>
    <v-container fluid class="py-16 mx-auto">
      <v-img
        :src="require('@/assets/logo.svg')"
        class="mt-0 mb-5"
        contain
        height="38"
      >
      </v-img>
      <p class="blue-grey--text text-center mb-12">
        Repository of Vocabularies and Schemas. β1.0
      </p>

      <v-col lg="4" md="12" class="mx-auto py-0">
        <!-- <p class="text-center mb-1">{{ getWords("top_search_v") }}</p> -->
        <v-radio-group v-model="searchType" class="mx-auto" mandatory row>
          <v-radio
            :label="getWords('top_search_v')"
            value="vocab"
          />
          <v-radio
            :label="getWords('top_search_m')"
            value="meta"
          />
        </v-radio-group>

        <v-text-field
          v-if="searchType === 'vocab'"
          :append-icon="searchIcon.vocab"
          filled
          rounded
          dense
          v-model="searchText.vocab"
          autocomplete="off"
          @keyup.enter="vocabularySearch(1)"
          @click:append="vocabularySearch(0)"
        ></v-text-field>
        <v-text-field
          v-if="searchType === 'meta'"
          :append-icon="searchIcon.meta"
          filled
          rounded
          dense
          v-model="searchText.meta"
          autocomplete="off"
          @keyup.enter="searchNameDescription(1)"
          @click:append="searchNameDescription(0)"
        ></v-text-field>
      </v-col>
    </v-container>

    <v-container class="pa-0">
      <v-tabs v-model="tab" centered class="pa-0" color="black">
        <v-tab v-for="(m, i) in menus" :key="i" :href="'#' + m">
          {{ m }}
        </v-tab>
        <v-tabs-slider color="#8F5F98"></v-tabs-slider>
      </v-tabs>
    </v-container>
    <v-divider class="mb-9"></v-divider>

    <v-card
      v-if="filteredGenres.length || filteredTags.length"
      outlined
      class="pa-4 mb-7"
    >
      <v-chip
        close
        label
        text-color="white"
        color="#5C5C5C"
        class="mr-4"
        v-for="(fg, i) in filteredGenres"
        :key="i"
        @click:close="removeFromFilteredGenres(fg)"
      >
        {{ fg }}
      </v-chip>
      <v-chip
        close
        label
        text-color="white"
        color="#8F5F98"
        class="mr-4"
        v-for="(ft, j) in filteredTags"
        :key="j"
        @click:close="removeFromFilteredTags(ft)"
      >
        #{{ ft }}
      </v-chip>
    </v-card>

    <v-container class="pa-0">
      <v-list v-if="tab === 'VOCABULARY'">
        <div v-if="isLoading === true" align="center">
          <v-progress-circular
            class="ma-4"
            indeterminate
            color="dark-grey"
          ></v-progress-circular>
        </div>
        <div v-if="vocabularies.length === 0" class="text-center">
          <p class="caption">{{ getWords("top_search_r") }}</p>
        </div>
        <v-container v-for="(data, i) in vocabularies" :key="i">
          <v-list-item three-line="" class="py-3 px-0">
            <v-list-item-avatar color="primary" size="48" class="my-0">
              v
            </v-list-item-avatar>
            <v-list-item-content class="mb-auto">
              <v-list-item-title style="font-size: 14px;" class="font-weight-bold mb-2">
                <router-link
                  :to="{
                    name: 'VocabularyShow',
                    params: { id: data['dcat:identifier'] },
                  }"
                  class="black--text"
                >
                  {{ getTextWithLocale(data["dct:title"]) }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle class="mb-3">
                {{ data["dcat:contactPoint"][0]["vcard:fn"] }}
                <span class="ml-2" color="purple darken-1">{{ data["dcat:contactPoint"][0]["vcard:hasEmail"] }}</span>
              </v-list-item-subtitle>

              <v-container v-if="data['dcat:theme'].length" class="pa-0 mb-6">
                <v-btn
                  v-for="(genre, i) in data['dcat:theme']"
                  :key="i"
                  depressed
                  small
                  class="mr-2"
                  @click="addToFilteredGenres(genre)"
                >
                  {{ genre }}
                </v-btn>
              </v-container>
              <v-container class="pa-0">
                {{ getTextWithLocale(data["dct:description"]) }}
              </v-container>
              <p class="caption mb-1">
                {{ getWords("name_meta_published") }}
                <v-btn text color="purple darken-1" class="caption pa-0">
                  {{ data["dct:publisher"] }}
                </v-btn>
              </p>

              <v-container class="pa-0">
                <v-btn
                  v-for="(tag, i) in data['dcat:keywords']"
                  :key="i"
                  depressed
                  small
                  color="#8F5F98"
                  class="white--text mr-2"
                  @click="addToFilteredTags(tag)"
                >
                  #{{ tag }}
                </v-btn>
              </v-container>
            </v-list-item-content>


            <v-list-item-content v-if="data.keyitems && data.keyitems.length > 0">
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="text-text-subtitle-1">
                      - {{ getWords("top_list_match") }} ({{ data.keyitems.length }})
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-group v-for="(keyitem, i) in data.keyitems" :key="i" sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-1" v-if="keyitem.type === 'SKOS'">
                        - {{ getTitleLabel(keyitem.item["skos:prefLabel"]) }}
                      </v-list-item-title>
                      <v-list-item-title class="text-body-1" v-else>
                        - {{ getTitleLabel(keyitem.item["rdfs:label"]) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <div v-if="keyitem.type === 'SKOS'" class="pl-2">
                    <v-list-item>
                      URI: {{ keyitem.item["uri"] }}
                    </v-list-item>
                    <v-list-item
                      v-for="(value, v) in keyitem.item['skos:prefLabel']"
                      :key="v + value"
                    >
                      {{ getWords("vocabulary_view_skos1") }}: {{ value }}
                    </v-list-item>
                    <v-list-item
                      v-for="(value, v) in keyitem.item['skos:altLabel']"
                      :key="v + value"
                    >
                      {{ getWords("vocabulary_view_skos2") }}: {{ value }}
                    </v-list-item>
                    <v-list-item
                      v-for="(value, v) in keyitem.item['skos:description']"
                      :key="v + value"
                    >
                      {{ getWords("vocabulary_view_skos3") }}: {{ value }}
                    </v-list-item>
                    <v-list-item
                      v-for="(value, v) in keyitem.item['skos:broadMatch']"
                      :key="v + value"
                    >
                      {{ getWords("vocabulary_view_skos4") }}: {{ value }}
                    </v-list-item>
                    <v-list-item
                      v-for="(value, v) in keyitem.item['skos:exactMatch']"
                      :key="v + value"
                    >
                      {{ getWords("vocabulary_view_skos5") }}: {{ value }}
                    </v-list-item>
                  </div>
                  <div v-else class="pl-2">
                    <v-list-item>
                      {{ keyitem.item["uri"] }}
                    </v-list-item>
                    <v-list-item
                      v-for="value in keyitem.item['rdfs:label']"
                      :key="value"
                    >
                      {{ value }}
                    </v-list-item>
                    <v-list-item
                      v-for="subkey of getExistSubKeyList(keyitem.item)"
                      :key="subkey"
                    >
                      <v-list-group
                        v-for="subItem in keyitem.item[subkey]"
                        :key="'sub_' + subItem['uri']"
                        sub-group
                        :group="subItem['uri']"
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title class="text-subtitle-2">
                              - {{ getTitleLabel(subItem["rdfs:label"]) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              - {{ subkey }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <div>
                          <v-list-item class="text-body-2">
                            {{ subItem["uri"] }}
                          </v-list-item>
                          <v-list-item
                            v-for="value in subItem['rdfs:label']"
                            :key="value"
                            class="text-body-2"
                          >
                            {{ value }}
                          </v-list-item>
                        </div>
                      </v-list-group>
                    </v-list-item>
                  </div>
                </v-list-group>
              </v-list-group>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-container>
      </v-list>

      <IndexSchema v-if="tab === 'SCHEMA'" :vocabularies="vocabularies" />
    </v-container>
    <!-- <v-row class="text-center my-13">
      <v-btn outlined class="mx-auto">MORE</v-btn>
    </v-row> -->
  </v-container>
</template>

<script>
// Component
import IndexSchema from "../components/IndexSchema";
import {
  getPublicVocabularies,
  getPublicVocabulariesWithKeywords,
} from "../plugins/firebase";
import words from "../assets/localewords.json";

export default {
  name: "Index",
  components: {
    IndexSchema,
  },

  data() {
    return {
      searchText: {
        meta: "",
        vocab: "",
      },
      searchType: "meta",
      vocabularies: [],
      allVocabularies: [],
      menus: ["VOCABULARY" /*, "SCHEMA", "TERMS"*/],
      scrollY: 0,
      tab: null,
      filteredGenres: [],
      filteredTags: [],
      filterText: "",
      searchIcon: {
        vocab: "mdi-magnify",
        meta: "mdi-magnify",
      },
      isLoading: false,
      subKeys: ["rdfs:subClassOf", "rdfs:range", "rdfs:domain"],
    };
  },

  watch: {
    vocabularies() {
      // console.log(this.vocabularies);
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  created() {
    this.getVocabularies();
  },

  computed: {
    getLanguage() {
      return this.$store.state.language;
    },
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
    getVocabularies: async function () {
      this.isLoading = true;
      try {
        this.allVocabularies = await getPublicVocabularies();
        this.vocabularies = this.allVocabularies;
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },

    getVocabulariesWithKeyWords: async function (keywords) {
      this.isLoading = true;
      try {
        this.allVocabularies = await getPublicVocabulariesWithKeywords(keywords);
        this.vocabularies = this.allVocabularies;
        // console.log(this.vocabularies)
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },

    getTextWithLocale(text) {
      if (this.getLanguage === "ja") {
        if (text.ja !== "") {
          return text.ja;
        } else {
          return text.en;
        }
      } else {
        if (text.en !== "") {
          return text.en;
        } else {
          return text.ja;
        }
      }
    },

    getTitleLabel(array) {
      const langCode = "@" + this.getLanguage;
      const target = array.find((item) => item.split(langCode).length > 1);
      if (target === undefined) {
        return array[0];
      } else {
        return target.split(langCode)[0];
      }
    },

    getJoinedTextWithLocale(text) {
      let total = "";
      Object.keys(text).map((value) => {
        total = total + value;
        return true;
      });
    },

    vocabularySearch: async function (mode) {
      if (mode === 0) {
        if (this.searchIcon.vocab === "mdi-close") {
          this.searchText.vocab = "";
        }
      }
      if (this.searchText.vocab === "") {
        this.searchIcon.vocab = "mdi-magnify";
      } else {
        this.searchIcon.vocab = "mdi-close";
      }
      const low = this.searchText.vocab.toLowerCase();
      const queries = low.split(",") // low.replace(",", " ").split(" ");
      if (queries.length === 0 || (queries.length === 1 && queries[0] === "")) {
        await this.getVocabularies();
      } else {
        await this.getVocabulariesWithKeyWords(queries);
      }
      this.filterVocabularies();
    },

    searchNameDescription(mode) {
      if (mode === 0) {
        if (this.searchIcon.meta === "mdi-close") {
          this.searchText.meta = "";
        }
      }
      if (this.searchText.meta === "") {
        this.searchIcon.meta = "mdi-magnify";
      } else {
        this.searchIcon.meta = "mdi-close";
      }
      this.filterText = this.searchText.meta.toUpperCase();
      this.filterVocabularies();
    },

    filterVocabularies() {
      // MetaData KeyWords
      const findString = this.filterText;
      const self = this;
      const list = this.allVocabularies.filter((vocabulary) => {
        const title = Object.keys(vocabulary["dct:title"]).find((value) => {
          const target = vocabulary["dct:title"][value].toUpperCase();
          return target.indexOf(findString) >= 0;
        });
        const desc = Object.keys(vocabulary["dct:description"]).find((value) => {
          const target = vocabulary["dct:description"][value].toUpperCase();
          return target.indexOf(findString) >= 0;
        });
        return title || desc;
      });

      // Genres, Tags
      const list2 = list.filter((vocabulary) => {
        const tags = vocabulary["dcat:keywords"];
        const categories = vocabulary["dcat:theme"];
        const resEveFg = self.filteredGenres.every((fg) => {
          return categories.filter((value) => value.indexOf(fg) >= 0).length > 0;
        });
        const resEveFt = self.filteredTags.every((ft) => {
          return tags.filter((value) => value.indexOf(ft) >= 0).length > 0;
        });
        return resEveFg && resEveFt;
      });
      this.vocabularies = list2;
    },

    addToFilteredGenres(chosenFg) {
      if (this.filteredGenres.includes(chosenFg)) {
        return;
      }
      this.filteredGenres.push(chosenFg);
      this.filterVocabularies();
    },

    removeFromFilteredGenres(chosenFg) {
      this.filteredGenres = this.filteredGenres.filter((fg) => {
        return fg !== chosenFg;
      });
      this.filterVocabularies();
      // this.filterVocabulariesWhenRemoving();
    },

    addToFilteredTags(chosenFt) {
      // chosenFt => chosen Filtered Tags
      if (this.filteredTags.includes(chosenFt)) {
        return;
      }
      this.filteredTags.push(chosenFt);
      this.filterVocabularies();
      // this.filterVocabulariesWhenAdding();
    },

    removeFromFilteredTags(chosenFt) {
      // chosenFt => chosen Filtered Tags
      this.filteredTags = this.filteredTags.filter((ft) => {
        return ft !== chosenFt;
      });
      this.filterVocabularies();
      // this.filterVocabulariesWhenRemoving();
    },

    // filterVocabulariesWhenAdding() {
    //   const list = this.allVocabularies.filter((vocabulary) => {
    //     const tags = vocabulary["dcat:keywords"];
    //     const categories = vocabulary["dcat:theme"];
    //     const resEveFg = this.filteredGenres.every((fg) => {
    //       return categories.filter((value) => value.indexOf(fg) >= 0).length > 0;
    //     });
    //     const resEveFt = this.filteredTags.every((ft) => {
    //       return tags.filter((value) => value.indexOf(ft) >= 0).length > 0;
    //     });
    //     return resEveFg && resEveFt;
    //   });
    //   this.vocabularies = list;
    // },

    // filterVocabulariesWhenRemoving() {
    //   const list = this.allVocabularies.filter((vocabulary) => {
    //     const tags = vocabulary["dcat:keywords"];
    //     const categories = vocabulary["dcat:theme"];
    //     const resEveFg = this.filteredGenres.every((fg) => {
    //       return categories.filter((value) => value.indexOf(fg) >= 0).length > 0;
    //     });
    //     const resEveFt = this.filteredTags.every((ft) => {
    //       return tags.filter((value) => value.indexOf(ft) >= 0).length > 0;
    //     });
    //     return resEveFg && resEveFt;
    //   });
    //   this.vocabularies = list;
    // },

    addBtnPressed() {
      if (this.$store.state.login) {
        this.$router.push({ name: "Type" }).catch((err) => console.log(err));
      } else {
        this.$router.push({ name: "Login" }).catch((err) => console.log(err));
      }
    },
    getExistSubKeyList(item) {
      const keys = this.subKeys.filter((subkey) => {
        return item[subkey];
      });
      return keys;
    },
  },

  // firestore() {
  //   return {
  //     vocabularies: db.collection("vocabularies"),
  //   };
  // },
};
</script>

<template>
  <v-list>
    <v-container v-for="(data, i) in schemas" :key="i" class="pa-0">
      <v-list-item three-line="" class="pb-6">
        <v-list-item-avatar color="primary" size="48">
          <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <router-link
              :to="{ name: 'SchemaShow', params: { id: data.id } }"
              class="black--text"
            >
              {{ data.name }}
            </router-link>
          </v-list-item-title>
          <v-list-item-subtitle class="mb-3">
            {{ data.uri }}
          </v-list-item-subtitle>
          <v-container class="pa-0 mb-6">
            <v-btn
              v-for="(genre, i) in data.genres"
              :key="i"
              depressed
              class="mr-2"
            >
              {{ genre }}
            </v-btn>
          </v-container>
          <v-container class="pa-0">
            {{ data.description }}
          </v-container>
          <p class="caption mb-4">
            published by
            <v-btn text color="purple darken-1" class="caption pa-0">
              {{ data.publishedBy }}
            </v-btn>
          </p>

          <v-container class="pa-0">
            <v-btn
              v-for="(tag, i) in data.tags"
              :key="i"
              depressed
              color="#8F5F98"
              class="white--text mr-2"
            >
              #{{ tag }}
            </v-btn>
          </v-container>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-container>
  </v-list>
</template>
<script>
// mBas Library
import { db } from "../plugins/firebase";

export default {
  props: ["vocabularies"],

  data() {
    return {
      schemas: [],
      filteredGenres: [],
      filteredTags: [],
    };
  },

  methods: {
    /*addToFilteredGenres(chosenFg) {
      if (this.filteredGenres.includes(chosenFg)) {
        return;
      }
      this.filteredGenres.push(chosenFg);

      this.filterSchemasWhenAdding();
    },

    removeFromFilteredGenres(chosenFg) {
      this.filteredGenres = this.filteredGenres.filter((fg) => {
        return fg !== chosenFg;
      });

      this.filterSchemasWhenRemoving();
    },

    addToFilteredTags(chosenFt) {
      // chosenFt => chosen Filtered Tags
      if (this.filteredTags.includes(chosenFt)) {
        return;
      }
      this.filteredTags.push(chosenFt);

      this.filterSchemasWhenAdding();
    },

    removeFromFilteredTags(chosenFt) {
      // chosenFt => chosen Filtered Tags
      this.filteredTags = this.filteredTags.filter((ft) => {
        return ft !== chosenFt;
      });

      this.filterSchemasWhenRemoving();
    },

    filterSchemasWhenAdding() {
      const schemas = this.schemas.filter((v) => {
        const resEveFg = this.filteredGenres.every((fg) => {
          return v.genres.includes(fg);
        });
        const resEveFt = this.filteredTags.every((ft) => {
          return v.tags.includes(ft);
        });
        return resEveFg && resEveFt;
      });
      this.schemas = schemas;
    },

    filterSchemasWhenRemoving() {
      this.$bind("schemas", db.collection("schemas")).then((schemas) => {
        const filteredSchemas = schemas.filter((v) => {
          const resEveFg = this.filteredGenres.every((fg) => {
            return v.genres.includes(fg);
          });
          const resEveFt = this.filteredTags.every((ft) => {
            return v.tags.includes(ft);
          });
          return resEveFg && resEveFt;
        });
        this.schemas = filteredSchemas;
      });
    },*/
  },

  firestore() {
    return {
      schemas: db.collection("schemas"),
    };
  },
};
</script>
<style scoped></style>
